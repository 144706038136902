// noscript.scss

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

// no js navbar start
#nojs-label {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
// dont show checkbox input cause of no js trickery
#navbar-toggle-nojs {
    display:none
}
#navbar-toggle-nojs:checked ~ .collapse {
    display: block;
}

.navbar .nav-item {
    padding: 0;
}
@include media-breakpoint-down(xs) {
    .dropdown-menu {
        display: block;
    }
}
// no js navbar end

// TODO: create noscript.scss and include in template

// TODO: CREATE SOMETHING FOR HEADER SEARCHBUTTON LIKE THE MENU
